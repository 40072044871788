import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useStoreState } from "easy-peasy"
import { navigate } from "@reach/router"
import Layout from "@layouts/MainLayout"
import Container from "@layouts/Container"
import { Box, Typography, List, ListItem, Hidden, SvgIcon } from "@lib/ui/core"

import Profile from "@components/UserCenter/Profile"
import QuizHistory from "@components/UserCenter/QuizHistory"
import Upload from "@components/UserCenter/Upload"
import Bookmark from "@components/UserCenter/Bookmark"
import PaymentHistory from "@components/UserCenter/PaymentHistory"
import UnfinishedQuiz from "@components/UserCenter/UnfinishedQuiz"

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos"

const DivideBar = styled.div`
  background-color: #4d4d4d;
  height: 1px;
  width: 100%;
  border-radius: 5px;
`

const flexContainer = {
  display: "flex",
  flexDirection: "row",
  padding: 0,
  justifyContent: "center",
}

const UserPage = () => {
  const [section, setSection] = useState("profile")
  const accessToken = useStoreState(
    ({ localStorage }) => localStorage.auth.accessToken
  )

  const renderComponent = (type) => {
    switch (type) {
      case "profile":
        return <Profile />
      case "quizHistory":
        return <QuizHistory />
      case "upload":
        return <Upload />
      case "bookmark":
        return <Bookmark />
      case "paymentHistory":
        return <PaymentHistory />
      case "unfinishedQuiz":
        return <UnfinishedQuiz />
      default:
        return <Profile />
    }
  }
  const fetchData = async () => {}
  useEffect(() => {
    if (!accessToken) navigate("/")
    fetchData()
  }, [])
  return (
    <Layout>
      <Box>
        <Container>
          <Box display="flex" justifyContent="center" color="#81C0FF" pt={4}>
            <Typography variant="subtitle1">會員中心</Typography>
          </Box>
          <DivideBar />
          <Hidden mdDown>
            <Box display="flex" pt={5}>
              <Box pr={2}>
                <List>
                  <Box mb={5} bgcolor={section === "profile" && "#81c0ff"}>
                    <ListItem button onClick={() => setSection("profile")}>
                      <Box
                        color={section === "profile" ? "#fff" : "#4D4D4D"}
                        display="flex"
                        alignItems="center"
                      >
                        <Box mx={10}>
                          <Typography noWrap variant="h2">
                            帳戶資料
                          </Typography>
                        </Box>
                        <SvgIcon
                          component={ArrowForwardIosIcon}
                          fontSize="16px"
                        />
                      </Box>
                    </ListItem>
                  </Box>
                  <Box
                    mb={5}
                    bgcolor={section === "paymentHistory" && "#81c0ff"}
                  >
                    <ListItem
                      button
                      onClick={() => setSection("paymentHistory")}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        color={
                          section === "paymentHistory" ? "#fff" : "#4D4D4D"
                        }
                      >
                        <Box mx={10}>
                          <Typography noWrap variant="h2">
                            交易記錄
                          </Typography>
                        </Box>
                        <SvgIcon
                          component={ArrowForwardIosIcon}
                          fontSize="16px"
                        />
                      </Box>
                    </ListItem>
                  </Box>
                  <Box
                    mb={5}
                    bgcolor={section === "unfinishedQuiz" && "#81c0ff"}
                  >
                    <ListItem
                      button
                      onClick={() => setSection("unfinishedQuiz")}
                    >
                      <Box
                        display="flex"
                        alignItems="center"
                        color={
                          section === "unfinishedQuiz" ? "#fff" : "#4D4D4D"
                        }
                        position="relative"
                      >
                        <Box mx={10}>
                          <Typography noWrap variant="h2">
                            未完成練習
                          </Typography>
                        </Box>
                        <Box position="absolute" right={0}>
                          <SvgIcon
                            component={ArrowForwardIosIcon}
                            fontSize="16px"
                          />
                        </Box>
                      </Box>
                    </ListItem>
                  </Box>
                  <Box mb={5} bgcolor={section === "quizHistory" && "#81c0ff"}>
                    <ListItem button onClick={() => setSection("quizHistory")}>
                      <Box
                        display="flex"
                        alignItems="center"
                        color={section === "quizHistory" ? "#fff" : "#4D4D4D"}
                      >
                        <Box mx={10}>
                          <Typography noWrap variant="h2">
                            過往練習
                          </Typography>
                        </Box>
                        <SvgIcon
                          component={ArrowForwardIosIcon}
                          fontSize="16px"
                        />
                      </Box>
                    </ListItem>
                  </Box>
                  <Box mb={5} bgcolor={section === "bookmark" && "#81c0ff"}>
                    <ListItem button onClick={() => setSection("bookmark")}>
                      <Box
                        display="flex"
                        alignItems="center"
                        color={section === "bookmark" ? "#fff" : "#4D4D4D"}
                      >
                        <Box mx={10}>
                          <Typography noWrap variant="h2">
                            收藏題目
                          </Typography>
                        </Box>
                        <SvgIcon
                          component={ArrowForwardIosIcon}
                          fontSize="16px"
                        />
                      </Box>
                    </ListItem>
                  </Box>
                  <Box mb={5} bgcolor={section === "upload" && "#81c0ff"}>
                    <ListItem button onClick={() => setSection("upload")}>
                      <Box
                        display="flex"
                        alignItems="center"
                        color={section === "upload" ? "#fff" : "#4D4D4D"}
                      >
                        <Box mx={10}>
                          <Typography noWrap variant="h2">
                            上傳練習
                          </Typography>
                        </Box>
                        <SvgIcon
                          component={ArrowForwardIosIcon}
                          fontSize="16px"
                        />
                      </Box>
                    </ListItem>
                  </Box>
                </List>
              </Box>
              <Box
                bgcolor="#F7F8F8"
                width="100%"
                display="flex"
                justifyContent="center"
              >
                {renderComponent(section)}
              </Box>
            </Box>
          </Hidden>
          <Hidden lgUp>
            <Box pt={5}>
              <List style={flexContainer}>
                <Box mb={2} bgcolor={section === "profile" && "#81c0ff"}>
                  <ListItem button onClick={() => setSection("profile")}>
                    <Box
                      mx={{ sm: 0, md: 2 }}
                      color={section === "profile" ? "#fff" : "#4D4D4D"}
                    >
                      <Typography noWrap variant="h2">
                        帳戶資料
                      </Typography>
                    </Box>
                  </ListItem>
                </Box>
                <Box mb={2} bgcolor={section === "paymentHistory" && "#81c0ff"}>
                  <ListItem button onClick={() => setSection("paymentHistory")}>
                    <Box
                      mx={{ sm: 0, md: 2 }}
                      color={section === "paymentHistory" ? "#fff" : "#4D4D4D"}
                    >
                      <Typography noWrap variant="h2">
                        交易記錄
                      </Typography>
                    </Box>
                  </ListItem>
                </Box>
                <Box mb={2} bgcolor={section === "unfinishedQuiz" && "#81c0ff"}>
                  <ListItem button onClick={() => setSection("unfinishedQuiz")}>
                    <Box
                      mx={{ sm: 0, md: 2 }}
                      color={section === "unfinishedQuiz" ? "#fff" : "#4D4D4D"}
                    >
                      <Typography noWrap variant="h2">
                        未完成練習
                      </Typography>
                    </Box>
                  </ListItem>
                </Box>
                <Box mb={2} bgcolor={section === "quizHistory" && "#81c0ff"}>
                  <ListItem button onClick={() => setSection("quizHistory")}>
                    <Box
                      mx={{ sm: 0, md: 2 }}
                      color={section === "quizHistory" ? "#fff" : "#4D4D4D"}
                    >
                      <Typography noWrap variant="h2">
                        過往練習
                      </Typography>
                    </Box>
                  </ListItem>
                </Box>
                <Box mb={2} bgcolor={section === "bookmark" && "#81c0ff"}>
                  <ListItem button onClick={() => setSection("bookmark")}>
                    <Box
                      mx={{ sm: 0, md: 2 }}
                      color={section === "bookmark" ? "#fff" : "#4D4D4D"}
                    >
                      <Typography noWrap variant="h2">
                        收藏題目
                      </Typography>
                    </Box>
                  </ListItem>
                </Box>
                <Box mb={2} bgcolor={section === "upload" && "#81c0ff"}>
                  <ListItem button onClick={() => setSection("upload")}>
                    <Box
                      mx={{ sm: 0, md: 2 }}
                      color={section === "upload" ? "#fff" : "#4D4D4D"}
                    >
                      <Typography noWrap variant="h2">
                        上傳練習
                      </Typography>
                    </Box>
                  </ListItem>
                </Box>
              </List>
              <Box
                bgcolor="#F7F8F8"
                width="100%"
                display="flex"
                justifyContent="center"
              >
                {renderComponent(section)}
              </Box>
            </Box>
          </Hidden>
        </Container>
      </Box>
    </Layout>
  )
}

export default UserPage
