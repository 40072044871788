import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import { Box, Button, Typography, SvgIcon } from "@lib/ui/core"

import { ReactComponent as StarIcon } from "@assets/icons/star.svg"
import { ReactComponent as TickIcon } from "@assets/icons/tick.svg"
import { ReactComponent as CrossIcon } from "@assets/icons/cross.svg"

import { getProfile } from "@services/user"
import { getPortal, getCurrentSubscription } from "@services/subscription"
import FirstFillProfile from "@components/FirstFillProfile"
import ChangePW from "@components/ChangePW"

import Spinner from "@components/Spinner"

const StyledButton = styled(Button)`
  background-color: #007bff !important;
  padding: 0.25rem 1.5rem;
  border-radius: 22px;
  color: #fff !important;
`

const CustomChip = styled(Box)`
  display: inline-block;
  background-color: ${({ bgcolor }) => bgcolor};
  color: #ffffff;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 25px;
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
`

const DivideBar = styled.div`
  background-color: #4d4d4d;
  height: 1px;
  width: 100%;
  border-radius: 5px;
`

const formattDate = (date) => {
  if (!date) return ""
  const processDate = new Date(date)
  return processDate.toLocaleString("en-GB").substring(0, 10)
}

const planName = (id) => {
  switch (id) {
    case "price_1Hp4XeBJQAxWyqyq0j09p9MC":
      return "Subscription (Monthly)"
    case "price_1I2vxmBJQAxWyqyqOeQx5Q8S":
      return "Subscription (Three Month)"
    case "price_1Hp4XeBJQAxWyqyqIRHlNIBc":
      return "Subscription (Yearly)"
    default:
      return "Offline Payment Subscription"
  }
}

const Profile = () => {
  const [detail, setDetail] = useState(null)
  const [subscriptionDetail, setSubscriptionDetail] = useState(null)
  const [alert, setAlert] = useState(false)
  const [pwVisible, setPwVisible] = useState(false)
  const [dataVisible, setDataVisible] = useState(false)

  const redirectToPortal = async () => {
    try {
      const data = await getPortal()
      if (data && data.url) {
        window.location.replace(data.url)
      } else {
        throw "Error"
      }
    } catch (e) {
      setAlert(true)
    }
  }

  const fetchData = async () => {
    try {
      const result = await getProfile()
      const subscriptionResult = await getCurrentSubscription()
      setDetail(result.item)
      console.log(subscriptionResult)
      setSubscriptionDetail(subscriptionResult.item)
      setDataVisible(false)
    } catch (e) {
      setAlert(true)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box p={5} width="100%">
      {detail ? (
        <Box>
          <Box p={1}>
            <Box color="#4d4d4d" fontSize="25px">
              會員資料
            </Box>
            <Box py={2}>
              <DivideBar />
            </Box>
            <Box py={1}>
              <Box display="flex" fontSize="16px">
                <Box fontWeight="700" width="30%">
                  用戶名稱：
                </Box>
                <Box width="60%">{detail.name}</Box>
              </Box>
            </Box>
            <Box py={1}>
              <Box display="flex">
                <Box fontWeight="700" width="30%">
                  電郵：
                </Box>
                <Box width="60%">{detail.email}</Box>
                {/* <Box
                  width="10%"
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center"
                  color="#007bff"
                  fontSize="10px"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                >
                  更改電郵
                </Box> */}
              </Box>
            </Box>
            <Box py={1}>
              <Box display="flex">
                <Box fontWeight="700" width="30%">
                  電話：
                </Box>
                <Box width="60%">{detail.phone}</Box>
              </Box>
            </Box>
            <Box py={1}>
              <Box display="flex" fontSize="16px">
                <Box fontWeight="700" width="30%">
                  學校：
                </Box>
                <Box width="60%">{detail.school}</Box>
              </Box>
            </Box>
            <Box py={1}>
              <Box display="flex" fontSize="16px">
                <Box fontWeight="700" width="30%">
                  年級：
                </Box>
                <Box width="60%">{detail.school_grade}</Box>
              </Box>
            </Box>
          </Box>
          <Box py={1}>
            <Box display="flex" justifyContent="center">
              <Button
                variant="contained"
                px={2}
                onClick={() => {
                  setPwVisible(true)
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  更改密碼
                </Typography>
              </Button>
              <Button
                variant="contained"
                px={2}
                mx={2}
                onClick={() => {
                  setDataVisible(true)
                }}
              >
                <Typography variant="body2" color="textSecondary">
                  更改資料
                </Typography>
              </Button>
            </Box>
          </Box>
          {subscriptionDetail && (
            <Box p={1}>
              <Box color="#4d4d4d" fontSize="25px">
                訂閱
              </Box>
              <Box py={2}>
                <DivideBar />
              </Box>
              <Box py={1}>
                <Box display="flex" fontSize="16px">
                  <Box fontWeight="700" width="30%">
                    訂閱計劃：
                  </Box>
                  <Box display="flex" justifyContent="center">
                    {subscriptionDetail.subscription_type === "stripe"
                      ? subscriptionDetail.stripe.plan.nickname
                      : planName(subscriptionDetail.stripe_plan)}
                  </Box>
                </Box>
              </Box>
              <Box py={1}>
                <Box display="flex">
                  <Box fontWeight="700" width="30%">
                    {subscriptionDetail.subscription_type === "stripe"
                      ? "下一個付款日期："
                      : "到期日期："}
                  </Box>
                  <Box>
                    {subscriptionDetail.subscription_type === "stripe"
                      ? formattDate(
                          subscriptionDetail.stripe.current_period_end * 1000
                        )
                      : formattDate(subscriptionDetail.ends_at)}
                  </Box>
                </Box>
              </Box>
              <Box py={1}>
                <Box display="flex" justifyContent="center">
                  <Button
                    variant="contained"
                    px={2}
                    onClick={() => {
                      redirectToPortal()
                    }}
                  >
                    <Typography variant="body2" color="textSecondary">
                      修改訂閱資料
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          <FirstFillProfile
            record={detail}
            open={dataVisible}
            fetchProfile={fetchData}
            isChange
            handleClose={() => {
              setDataVisible(false)
            }}
          />
          <ChangePW
            open={pwVisible}
            handleClose={() => {
              setPwVisible(false)
            }}
          />
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      )}
    </Box>
  )
}

export default Profile
