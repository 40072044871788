import axios from "axios"
import urljoin from "url-join"
import { loadStripe } from "@stripe/stripe-js"
import store from "@store"
import { refresh } from "./auth"

const apiBaseUrl = "https://api.mathod.info/api/v1"

export const getProduct = async () => {
  const url = urljoin(apiBaseUrl, "/product")
  try {
    const response = await axios.get(url)
    return response
  } catch (e) {
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const getSessionID = async (
  id,
  mode,
  successPath = "/exercise/select",
  cancelPath = "/subscription"
) => {
  const url = urljoin(apiBaseUrl, "/checkout/createSession")
  const currentURl = typeof window !== "undefined" ? window.location.origin : ""
  const successRedirectUrl = currentURl + successPath
  const cancelRedirectUrl = currentURl + cancelPath
  const { accessToken } = store.getState().localStorage.auth

  const params = {
    success_url: successRedirectUrl,
    cancel_url: cancelRedirectUrl,
    product_id: id,
    mode: mode,
  }

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.post(url, params, options)
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.post(url, params, newOptions)
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const checkout = async (id, mode, successPath, cancelPath) => {
  try {
    const data = await getSessionID(id, mode, successPath, cancelPath)
    if (data) {
      const stripePromise = loadStripe(data.stripe_key)
      const stripe = await stripePromise
      const result = await stripe.redirectToCheckout({
        sessionId: data.session.id,
      })
      return result
    } else {
      throw "No session Id"
    }
  } catch (e) {
    return false
  }
}

export const getSubscription = async () => {
  const url = urljoin(apiBaseUrl, "/subscription/list")

  const { accessToken } = store.getState().localStorage.auth

  const params = {}

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.get(url, { params, ...options })
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.get(url, { params, ...newOptions })
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const getInvoice = async () => {
  const url = urljoin(apiBaseUrl, "/invoice/list")

  const { accessToken } = store.getState().localStorage.auth

  const params = {}

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.get(url, { params, ...options })
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.get(url, { params, ...newOptions })
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const getPortal = async () => {
  const url = urljoin(apiBaseUrl, "/subscription/billing_portal")

  const { accessToken } = store.getState().localStorage.auth

  const currentURl = typeof window !== "undefined" ? window.location.origin : ""
  const redirectUrl = currentURl + "/user/"
  const params = {
    return_url: redirectUrl,
  }

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.get(url, { params, ...options })
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.get(url, { params, ...newOptions })
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}

export const getCurrentSubscription = async () => {
  const url = urljoin(apiBaseUrl, "/subscription/current")

  const { accessToken } = store.getState().localStorage.auth

  const params = {}

  const options = {
    headers: {
      authorization: `Bearer ${accessToken}`,
    },
  }

  try {
    const response = await axios.get(url, { params, ...options })
    return response.data
  } catch (e) {
    if (e.response.status === 401) {
      const refreshResponse = await refresh(accessToken)
      if (refreshResponse.data && refreshResponse.data.auth) {
        const newAccessToken = refreshResponse.data.auth.access_token
        const { updateAccessToken } = store.getActions().localStorage.auth
        updateAccessToken(newAccessToken)
        const newOptions = {
          headers: {
            authorization: `Bearer ${newAccessToken}`,
          },
        }
        const response = await axios.get(url, { params, ...newOptions })
        return response.data
      }
    }
    if (e.response.data) {
      return e.response
    }
  }
  return false
}
