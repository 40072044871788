import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { navigate } from "@reach/router"
import { Box, Button, Typography, SvgIcon, Pagination } from "@lib/ui/core"

import { ReactComponent as StarIcon } from "@assets/icons/star.svg"
import { ReactComponent as TickIcon } from "@assets/icons/tick.svg"
import { ReactComponent as CrossIcon } from "@assets/icons/cross.svg"

import { getProfile } from "@services/user"
import { getQuizHistory, deleteQuizHistory } from "@services/exercise"

import Spinner from "@components/Spinner"

const StyledButton = styled(Button)`
  background-color: #007bff !important;
  padding: 0.25rem 1.5rem;
  border-radius: 22px;
  color: #fff !important;
`

const CustomChip = styled(Box)`
  display: inline-block;
  background-color: ${({ bgcolor }) => bgcolor};
  color: #ffffff;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 25px;
  font-weight: ${({ bold }) => (bold ? "700" : "400")};
`

const DivideBar = styled.div`
  background-color: #4d4d4d;
  height: 1px;
  width: 100%;
  border-radius: 5px;
`

const QuizHistory = () => {
  const pageSize = 10
  const [fullHistory, setFullHistory] = useState([])
  const [currentHistory, setCurrentHistory] = useState([])
  const [page, setPage] = useState(1)
  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(true)

  const handlePageChange = (e, newPage) => {
    const indexOfLast = newPage * pageSize
    const indexOfFirst = indexOfLast - pageSize
    const newPageContent = fullHistory.slice(indexOfFirst, indexOfLast)
    setPage(newPage)
    setCurrentHistory([...newPageContent])
  }

  const modeText = {
    single_topic: "單一課題",
    multiple_topic: "綜合課題",
    quiz: "測驗模式",
    exam: "考試模式",
  }

  const gradeText = {
    "F.1": "中一",
    "F.2": "中二",
    "F.3": "中三",
    "F.4": "中四",
    "F.5": "中五/六",
  }

  const quizModeType = {
    "F.1": "LQ:6 + MC:10",
    "F.2": "LQ:6 + MC:10",
    "F.3": "LQ:6 + MC:10",
    "F.4": "LQ:6 + MC:10",
    "F.5": "LQ:6 + MC:15",
  }

  const examModeType = {
    lq: "Paper 1",
    mc: "Paper 2",
  }

  const renderType = (mode, type, grade) => {
    switch (mode) {
      case "single_topic":
        return type.toUpperCase()
      case "multiple_topic":
        return type.toUpperCase()
      case "quiz":
        return quizModeType[grade]
      case "exam":
        return examModeType[type]
      default:
        return "NA"
    }
  }

  const formattDate = (date) => {
    if (!date) return ""
    const processDate = new Date(date)
    return processDate.toLocaleString("en-GB").substring(0, 10)
  }

  const fetchData = async () => {
    try {
      const result = await getQuizHistory({ is_ended: 1 })
      if (result.items.length > -1) {
        setFullHistory(result.items)
        setCurrentHistory(result.items.slice(0, pageSize))
      }
      console.log(result)
      setLoading(false)
    } catch (e) {
      setAlert(true)
    }
  }

  const deleteHistory = async (id) => {
    await deleteQuizHistory(id)
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box p={5} width="100%">
      {!loading ? (
        <Box>
          <Box>
            <Box color="#4d4d4d" fontSize="25px" pb={2}>
              過往練習
            </Box>
            <Box
              display="flex"
              alignItems="center"
              color="#4D4D4D"
              width="100%"
              py={1}
            >
              <Box width="15%">日期</Box>
              <Box width="20%">練習名稱</Box>
              <Box width="15%">練習模式</Box>
              <Box width="15%">練習年級</Box>
              <Box width="15%">練習類型</Box>
              <Box width="5%">題數</Box>
            </Box>
            <Box pt={1} pb={2}>
              <DivideBar />
            </Box>
            {currentHistory.map((quiz) => (
              <Box
                color="#4D4D4D"
                display="flex"
                alignItems="center"
                width="100%"
                py={1}
              >
                <Box width="15%">{formattDate(quiz.start_time)}</Box>
                <Box width="20%" overflow="hidden">
                  {quiz.name}
                </Box>
                <Box width="15%">{modeText[quiz.mode]}</Box>
                <Box width="15%">{gradeText[quiz.grade]}</Box>
                <Box width="15%">
                  {renderType(quiz.mode, quiz.question_type, quiz.grade)}
                </Box>
                <Box width="5%">{quiz.questions_count}</Box>
                <Box width="5%">
                  <Box
                    color="#007bff"
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() =>
                      navigate(`/exercise/summary?quiz_id=${quiz.id}`)
                    }
                  >
                    詳細
                  </Box>
                </Box>
                <Box width="5%">
                  <Box
                    color="#007bff"
                    style={{
                      cursor: "pointer",
                      textDecoration: "underline",
                    }}
                    onClick={() => deleteHistory(quiz.id)}
                  >
                    刪除
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
          <Box display="flex" justifyContent="center" pt={2}>
            <Pagination
              count={Math.ceil(fullHistory.length / pageSize)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      )}
    </Box>
  )
}

export default QuizHistory
