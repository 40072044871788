import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Box, Pagination, Link } from "@lib/ui/core"

import { getInvoice } from "@services/subscription"

import Spinner from "@components/Spinner"

const DivideBar = styled.div`
  background-color: #4d4d4d;
  height: 1px;
  width: 100%;
  border-radius: 5px;
`

const PaymentHistory = () => {
  const pageSize = 10

  const [fullHistory, setFullHistory] = useState([])
  const [currentHistory, setCurrentHistory] = useState([])
  const [page, setPage] = useState(1)
  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(true)

  const handlePageChange = (e, newPage) => {
    const indexOfLast = newPage * pageSize
    const indexOfFirst = indexOfLast - pageSize
    const newPageContent = fullHistory.slice(indexOfFirst, indexOfLast)
    setPage(newPage)
    setCurrentHistory([...newPageContent])
  }

  const formattDate = (date) => {
    if (!date) return ""
    const processDate = new Date(date * 1000)
    return processDate.toLocaleString("en-GB").substring(0, 10)
  }

  const fetchData = async () => {
    try {
      const result = await getInvoice()
      if (result.items.length > 0) {
        setFullHistory(result.items)
        setCurrentHistory(result.items.slice(0, pageSize))
      }
      console.log(result)
      setLoading(false)
    } catch (e) {
      setAlert(true)
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box p={5} width="100%">
      {!loading ? (
        <Box>
          <Box>
            <Box color="#4d4d4d" fontSize="25px" pb={2}>
              交易記錄
            </Box>
            <Box
              display="flex"
              alignItems="center"
              color="#4D4D4D"
              width="100%"
            >
              <Box width="15%">付款日期</Box>
              <Box width="25%">交易編號</Box>
              <Box width="50%">付款內容</Box>
              {/* <Box width="15%" display="flex" justifyContent="center">
                服務週期至
              </Box>
              <Box width="15%" display="flex" justifyContent="center">
                付款方式
              </Box> */}
              <Box width="10%" display="flex" justifyContent="flex-end">
                金額(HKD)
              </Box>
            </Box>
            <Box pt={1} pb={2}>
              <DivideBar />
            </Box>
            {currentHistory.length > 0 &&
              currentHistory.map((history) => (
                <Box
                  color="#4D4D4D"
                  display="flex"
                  alignItems="center"
                  width="100%"
                  py={1}
                >
                  <Box width="15%">
                    {formattDate(history.status_transitions.paid_at)}
                  </Box>
                  <Box width="25%">
                    <Link href={history.invoice_pdf} target="_blank">
                      <Box fontSize="16px">{history.number}</Box>
                    </Link>
                  </Box>
                  <Box width="50%">{history.lines.data[0].description}</Box>
                  {/* <Box width="15%" display="flex" justifyContent="center">
                    未有資料
                  </Box>
                  <Box width="15%" display="flex" justifyContent="center">
                    未有資料
                  </Box> */}
                  <Box width="10%" display="flex" justifyContent="flex-end">
                    {history.total / 100}
                  </Box>
                </Box>
              ))}
          </Box>
          <Box display="flex" justifyContent="center" pt={2}>
            <Pagination
              count={Math.ceil(fullHistory.length / pageSize)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      )}
    </Box>
  )
}

export default PaymentHistory
