import React, { useState, useEffect, useCallback } from "react"
import styled from "styled-components"
import { useStoreState, useStoreActions } from "easy-peasy"
import { useDropzone } from "react-dropzone"
import {
  Box,
  Button,
  Typography,
  Pagination,
  ErrorAlert,
  DialogWithCloseButton,
  CircularProgress,
} from "@lib/ui/core"
import DeleteIcon from "@material-ui/icons/Delete"
import PublishIcon from "@material-ui/icons/Publish"
import {
  unlockUpload,
  uploadFile,
  getAttachments,
  getProfile,
} from "@services/user"
import { getQuizHistory } from "@services/exercise"
import Spinner from "@components/Spinner"
import UploadRule from "@assets/document/upload_rule"

const ButtonProgress = styled(CircularProgress)`
  position: absolute;
  margin-left: -80px;
  margin-top: 10px;
`

const CancelButton = styled(Button)`
  background-color: #808080;
  &:hover {
    background-color: #808080;
  }
`

const DivideBar = styled.div`
  background-color: #4d4d4d;
  height: 1px;
  width: 100%;
  border-radius: 5px;
`

const UplockModal = ({
  visible,
  handleClose,
  quiz_id,
  setSelectedQuiz,
  setErrorAlert,
  refresh,
}) => {
  const [isLoading, setIsLoading] = useState(false)

  const onFinish = async (id) => {
    setIsLoading(true)
    try {
      let data = await unlockUpload({
        quiz_id: id,
      })
      setSelectedQuiz(quiz_id)
      refresh()
      handleClose()
    } catch (err) {
      console.log(err)
      setErrorAlert({
        visible: true,
        msg: "系統出錯，請稍後再試。",
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Box>
      <DialogWithCloseButton
        onClose={() => {
          if (!isLoading) {
            handleClose()
          }
        }}
        open={visible}
        title="解鎖上傳功能需消耗一個硬幣"
      >
        <Box p={1}>
          <Typography variant="h5">確認要解鎖上傳功能?</Typography>
        </Box>
        <Box p={1}>
          <UploadRule />
        </Box>
        <Box p={1} display="flex" justifyContent="flex-end">
          <Box>
            <CancelButton mr={1} disabled={isLoading} onClick={handleClose}>
              <Typography color="textSecondary">取消</Typography>
            </CancelButton>
          </Box>
          <Box>
            <Button
              disabled={isLoading}
              onClick={() => {
                onFinish(quiz_id)
              }}
            >
              <Typography color="textSecondary">確認</Typography>
            </Button>
          </Box>
        </Box>
      </DialogWithCloseButton>
    </Box>
  )
}

const Upload = () => {
  const pageSize = 10
  const [isUploading, setIsUploading] = useState(false)
  const [fullHistory, setFullHistory] = useState([])
  const [currentHistory, setCurrentHistory] = useState([])
  const [selectedQuiz, setSelectedQuiz] = useState(null)
  const [uploadedFile, setUploadedFile] = useState([])
  const [uplockVars, setUplockVars] = useState({
    visible: false,
    id: null,
  })
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [token, setToken] = useState()

  const [errorAlert, setErrorAlert] = useState({
    visible: false,
    msg: "系統出錯，請稍後再試。",
  })

  const handlePageChange = (e, newPage) => {
    const indexOfLast = newPage * pageSize
    const indexOfFirst = indexOfLast - pageSize
    const newPageContent = fullHistory.slice(indexOfFirst, indexOfLast)
    setPage(newPage)
    setCurrentHistory([...newPageContent])
  }

  const updateReduxProfile = useStoreActions(
    ({ user }) => user.updateReduxProfile
  )

  const formattDate = (date) => {
    if (!date) return ""
    const processDate = new Date(date)
    return processDate.toLocaleString("en-GB").substring(0, 10)
  }

  const onDrop = useCallback(
    (acceptedFiles) => {
      if (uploadedFile.length + acceptedFiles.length > 30) {
        setErrorAlert({
          visible: true,
          msg: "每份試卷檔案上傳數目不得多於30個",
        })
      } else {
        let newFileList = uploadedFile.concat(acceptedFiles)
        setUploadedFile(newFileList)
      }
    },
    [uploadedFile]
  )

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: ".pdf,image/* ",
    noClick: true,
  })

  const selectItem = async (id) => {
    setIsUploading(true)
    try {
      let data = await getAttachments({ quiz_id: id })
      setUploadedFile(data.items)
      setSelectedQuiz(id)
    } catch (err) {
      console.log
    } finally {
      setIsUploading(false)
    }
  }

  const submitFiles = async (fileList, id) => {
    const onUnload = (e) => {
      e.preventDefault()
      e.returnValue = ""
    }
    let uploadList = fileList.filter((item) => !item.id)
    if (uploadList.length > 0) {
      try {
        window.addEventListener("beforeunload", onUnload)

        setIsUploading(true)
        let i
        for (i = 0; i < uploadList.length; i++) {
          await uploadFile({
            file: uploadList[i],
            quiz_id: id,
          })
        }
        selectItem(id)
      } catch (err) {
        setErrorAlert({
          visible: true,
          msg: "系統出錯，請稍後再試。",
        })
      } finally {
        setIsUploading(false)
        window.removeEventListener("beforeunload", onUnload)
      }
    } else {
      setErrorAlert({
        visible: true,
        msg: "請上傳檔案",
      })
    }
  }

  const deleteFiles = (index) => {
    let newFileList = [...uploadedFile]
    newFileList.splice(index, 1)
    setUploadedFile(newFileList)
  }

  const getToken = async () => {
    try {
      const result = await getProfile()
      setToken(result.item.coin_balance)
      updateReduxProfile(result.item)
    } catch (e) {
      setAlert(true)
    }
  }

  const refresh = async (page) => {
    try {
      await getToken()
      const result = await getQuizHistory({
        mode: ["quiz", "exam"],
      })
      if (result.items.length > 0) {
        setFullHistory(result.items)
        const indexOfLast = page * pageSize
        const indexOfFirst = indexOfLast - pageSize
        const newPageContent = result.items.slice(indexOfFirst, indexOfLast)
        setCurrentHistory(newPageContent)
      }
      setLoading(false)
    } catch (e) {
      setErrorAlert({
        visible: true,
        msg: "系統出錯，請稍後再試。",
      })
    }
  }

  const fetchData = async () => {
    try {
      await getToken()
      const result = await getQuizHistory({
        mode: ["quiz", "exam"],
      })
      if (result.items.length > 0) {
        setFullHistory(result.items)
        setCurrentHistory(result.items.slice(0, pageSize))
      }
      console.log(result)
      setLoading(false)
    } catch (e) {
      setErrorAlert({
        visible: true,
        msg: "系統出錯，請稍後再試。",
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box p={5} width="100%">
      {!loading ? (
        <Box>
          <Box
            display="flex"
            color="#4d4d4d"
            fontSize="25px"
            pb={2}
            width="100%"
            position="relative"
          >
            <Box>上傳練習</Box>
            <Box>
              <Box position="absolute" right={0}>
                Token: {token}
              </Box>
            </Box>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            color="#4D4D4D"
            width="100%"
            py={1}
          >
            <Box width="20%">日期</Box>
            <Box width="25%">練習名稱</Box>
          </Box>
          <Box pt={1} pb={2}>
            <DivideBar />
          </Box>
          {currentHistory.map((quiz) => (
            <Box>
              <Box
                display="flex"
                alignItems="center"
                color="#4D4D4D"
                width="100%"
                py={1}
              >
                <Box width="20%">{formattDate(quiz.start_time)}</Box>
                <Box width="25%">{quiz.name}</Box>
                {selectedQuiz !== quiz.id ? (
                  quiz.is_upload_unlocked ? (
                    quiz.is_corrected ? (
                      <Box
                        color={selectedQuiz === null ? "#007bff" : "#808080"}
                        style={{
                          cursor: "pointer",
                          textDecoration: "underline",
                        }}
                        onClick={() => {}}
                      >
                        查看結果
                      </Box>
                    ) : (
                      <Box display="flex">
                        <Box
                          mr={3}
                          display="inline-flex"
                          color={selectedQuiz === null ? "#007bff" : "#808080"}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={() => {
                            if (selectedQuiz === null) {
                              selectItem(quiz.id)
                            }
                          }}
                        >
                          選擇
                        </Box>
                        <Box display="inline-flex">批改中</Box>
                      </Box>
                    )
                  ) : (
                    <Box
                      color={
                        selectedQuiz === null && token > 0
                          ? "#007bff"
                          : "#808080"
                      }
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        if (selectedQuiz === null && token > 0) {
                          setUplockVars({
                            id: quiz.id,
                            visible: true,
                          })
                        }
                      }}
                    >
                      {token > 0 ? "解鎖上傳功能" : "需要先購買Token"}
                    </Box>
                  )
                ) : (
                  <Button
                    variant="text"
                    color="primary"
                    px={1}
                    startIcon={<PublishIcon />}
                    onClick={open}
                  >
                    上傳檔案
                  </Button>
                )}
              </Box>
              {selectedQuiz === quiz.id && (
                <Box>
                  <Box
                    {...getRootProps()}
                    height="300px"
                    bgcolor="#fff"
                    my={2}
                    style={{ overflowY: "auto" }}
                  >
                    <input {...getInputProps()} />
                    {uploadedFile.length > 0 ? (
                      uploadedFile.map((item, index) => (
                        <Box
                          display="flex"
                          height="10%"
                          p={3}
                          alignItems="center"
                          style={{ borderBottom: "1px solid grey" }}
                        >
                          <Box width="10%" display="inline-flex">
                            {index + 1 + ". "}
                          </Box>

                          <Box
                            width="80%"
                            display="inline-flex"
                            justifyContent="flex-start"
                          >
                            {!item.id ? (
                              item.name
                            ) : (
                              <a
                                href={item.file_url}
                                target="_blank"
                                download={"Uploaded file " + item.id}
                              >
                                {"Uploaded file " + item.id}
                              </a>
                            )}
                          </Box>

                          {!item.id && (
                            <Box width="10%" display="inline-flex">
                              <Button
                                color="default"
                                variant="text"
                                px={1}
                                startIcon={<DeleteIcon />}
                                onClick={() => {
                                  deleteFiles(index)
                                }}
                              >
                                刪除
                              </Button>
                            </Box>
                          )}
                        </Box>
                      ))
                    ) : (
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="inherit"
                      >
                        {isDragActive
                          ? "放開"
                          : "把檔案拖延到這裏，或點擊 '上傳檔案' "}
                      </Box>
                    )}
                  </Box>
                  <Box display="flex" justifyContent="flex-end">
                    <Box mr={3}>
                      <CancelButton
                        disabled={isUploading}
                        onClick={() => {
                          setSelectedQuiz(null)
                          setUploadedFile([])
                        }}
                      >
                        <Typography variant="body1" color="textSecondary">
                          取消
                        </Typography>
                      </CancelButton>
                    </Box>
                    <Box>
                      <Button
                        disabled={uploadedFile.length < 1 || isUploading}
                        onClick={() => {
                          submitFiles(uploadedFile, quiz.id)
                        }}
                      >
                        <Typography variant="body1" color="textSecondary">
                          上傳
                        </Typography>
                      </Button>
                      {isUploading && <ButtonProgress size={24} />}
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
          ))}
          <Box display="flex" justifyContent="center" pt={2}>
            <Pagination
              count={Math.ceil(fullHistory.length / pageSize)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      )}
      <UplockModal
        quiz_id={uplockVars.id}
        visible={uplockVars.visible}
        setSelectedQuiz={setSelectedQuiz}
        setErrorAlert={setErrorAlert}
        handleClose={() => {
          setUplockVars({
            visible: false,
            id: null,
          })
        }}
        refresh={() => {
          setUploadedFile([])
          refresh(page)
        }}
      />

      <ErrorAlert
        open={errorAlert.visible}
        handleClose={() => {
          setErrorAlert({
            visible: false,
            msg: null,
          })
        }}
      >
        {errorAlert.msg}
      </ErrorAlert>
    </Box>
  )
}

export default Upload
