import React from "react"

const UploadRule = () => {
  return (
    <div>
      <p>
        <span style={{ color: "#ff0000" }}>Rules (請細心閱讀)：</span>
        <br />
        <span style={{ color: "#ff0000" }}>
          1. 請確保圖片或PDF內的字體清晰可見並端正
        </span>
        <br />
        <span style={{ color: "#ff0000" }}>
          2. 每份練習，最多只能上傳30張圖片
        </span>
        <br />
        <span style={{ color: "#ff0000" }}>
          3. PDF 的頁數上限為30頁 (30頁後的頁數不會批改)
        </span>
        <br />
        <span style={{ color: "#ff0000" }}>4. 批改完成後 不能再次上傳</span>
        <br />
        <span style={{ color: "#ff0000" }}>
          5. 一份練習需要消費一個token，並且不能退回
        </span>
        <br />
        <span style={{ color: "#ff0000" }}>
          6. 不能將多於一份練習合拼上傳，否則作廢，並不會退回token
        </span>
      </p>
    </div>
  )
}

export default UploadRule
