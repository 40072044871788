import React, { useState, useEffect } from "react"
import styled from "styled-components"
import Imgix from "react-imgix"
import { animateScroll } from "react-scroll"
import { Box, Pagination } from "@lib/ui/core"

import { getBookmark, bookmarkQuestion } from "@services/exercise"

import Spinner from "@components/Spinner"

const DivideBar = styled.div`
  background-color: #4d4d4d;
  height: 1px;
  width: 100%;
  border-radius: 5px;
`

const Bookmark = () => {
  const pageSize = 5
  const [fullBookmark, setFullBookmark] = useState([])
  const [currentBookmark, setCurrentBookmark] = useState([])
  const [showQuestion, setShowQuestion] = useState([])
  const [showAnswer, setShowAnswer] = useState([])

  const [page, setPage] = useState(1)
  const [alert, setAlert] = useState(false)
  const [loading, setLoading] = useState(true)

  const handlePageChange = (e, newPage) => {
    const indexOfLast = newPage * pageSize
    const indexOfFirst = indexOfLast - pageSize
    const newPageContent = fullBookmark.slice(indexOfFirst, indexOfLast)
    animateScroll.scrollToTop()
    setPage(newPage)
    setCurrentBookmark([...newPageContent])
  }

  const formattDate = (date) => {
    if (!date) return ""
    const processDate = new Date(date)
    return processDate.toLocaleString("en-GB").substring(0, 10)
  }

  const fetchData = async () => {
    try {
      const result = await getBookmark()
      if (result.items.length > -1) {
        setFullBookmark(result.items)
        setCurrentBookmark(result.items.slice(0, pageSize))
      }
      console.log(result)
      setLoading(false)
    } catch (e) {
      setAlert(true)
    }
  }

  const deleteBoomark = async (id) => {
    await bookmarkQuestion({
      id,
    })
    fetchData()
  }

  useEffect(() => {
    fetchData()
  }, [])

  return (
    <Box p={5} width="100%">
      {!loading ? (
        <Box>
          <Box color="#4d4d4d" fontSize="25px" pb={2}>
            收藏題目
          </Box>
          <Box display="flex" alignItems="center" color="#4D4D4D" width="100%">
            <Box width="30%">收藏日期</Box>
            <Box width="50%">課題</Box>
          </Box>
          <Box pt={1} pb={2}>
            <DivideBar />
          </Box>
          {currentBookmark.map((bookmark) => (
            <Box>
              <Box
                display="flex"
                alignItems="center"
                color="#4D4D4D"
                width="100%"
                py={1}
              >
                <Box width="30%">{formattDate(bookmark.bookmarked_at)}</Box>
                <Box width="50%">{bookmark.topic.name}</Box>
                <Box width="10%">
                  {!showQuestion.includes(bookmark.id) ? (
                    <Box
                      color="#007bff"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() =>
                        setShowQuestion((prev) => [...prev, bookmark.id])
                      }
                    >
                      顯示題目
                    </Box>
                  ) : (
                    <Box
                      color="#007bff"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      onClick={() => {
                        setShowQuestion((prev) =>
                          prev.filter((ele) => ele !== bookmark.id)
                        )
                        setShowAnswer((prev) =>
                          prev.filter((ele) => ele !== bookmark.id)
                        )
                      }}
                    >
                      收起題目
                    </Box>
                  )}
                </Box>
                <Box
                  color="#007bff"
                  style={{
                    cursor: "pointer",
                    textDecoration: "underline",
                  }}
                  onClick={() => deleteBoomark(bookmark.id)}
                >
                  刪除
                </Box>
              </Box>
              {showQuestion.includes(bookmark.id) && (
                <Box>
                  <Box py={1}>
                    <Imgix src={bookmark.q_img.url} width="100%" />
                  </Box>
                  {!showAnswer.includes(bookmark.id) ? (
                    <Box
                      pb={1}
                      color="#007bff"
                      style={{
                        cursor: "pointer",
                        textDecoration: "underline",
                      }}
                      width="100%"
                      align="center"
                      onClick={() =>
                        setShowAnswer((prev) => [...prev, bookmark.id])
                      }
                    >
                      顯示答案
                    </Box>
                  ) : (
                    <Box py={1}>
                      <Imgix src={bookmark.ans_imgs[0].url} width="100%" />
                    </Box>
                  )}
                </Box>
              )}
            </Box>
          ))}
          <Box display="flex" justifyContent="center" pt={2}>
            <Pagination
              count={Math.ceil(fullBookmark.length / pageSize)}
              page={page}
              onChange={handlePageChange}
            />
          </Box>
        </Box>
      ) : (
        <Box display="flex" justifyContent="center" alignItems="center">
          <Spinner />
        </Box>
      )}
    </Box>
  )
}

export default Bookmark
