import React, { useState } from "react"
import { useStoreActions } from "easy-peasy"
import {
  Box,
  DialogWithCloseButton,
  TextField,
  Button,
  Typography,
  ErrorAlert,
} from "@lib/ui/core"

import { useForm } from "react-hook-form"
import { updatePW } from "@services/auth"

// eslint-disable-next-line arrow-body-style
const ChangePW = ({ open, handleClose }) => {
  const { register, handleSubmit, errors, clearErrors, watch } = useForm({
    mode: "onTouched",
  })
  const [submitError, setSubmitError] = useState(false)
  const [errorAlert, setErrorAlert] = useState(false)

  const closeAlert = () => {
    setErrorAlert(false)
  }

  const updateAccessToken = useStoreActions(
    ({ localStorage }) => localStorage.auth.updateAccessToken
  )

  const onSubmit = async (values) => {
    const data = await updatePW({
      password: values.password,
      new_password: values.new_password,
    })

    if (data.data && data.data.error) {
      setSubmitError(true)
    } else {
      updateAccessToken(data.item.auth.access_token)
      handleClose()
    }
  }

  return (
    <Box>
      <DialogWithCloseButton
        onClose={handleClose}
        open={open}
        title="更改密碼"
        onExited={() => {
          clearErrors()
          setSubmitError(false)
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box p={1}>
            <TextField
              type="password"
              error={errors.password}
              inputRef={register({
                required: "請輸入舊密碼",
              })}
              name="password"
              label="舊密碼"
              helperText={errors.password?.message}
            />
          </Box>
          <Box p={1}>
            <TextField
              type="password"
              error={errors.new_password}
              inputRef={register({
                required: "請輸入新密碼",
                pattern: {
                  value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d#$@!~%&*?]{8,}$/,
                  message:
                    "密碼必須至少包含八個字符、至少包含一個數字、小寫和大寫的字母",
                },
              })}
              name="new_password"
              label="新密碼"
              helperText={errors.new_password?.message}
            />
          </Box>
          <Box p={1}>
            <TextField
              type="password"
              error={errors.confirmPassword}
              inputRef={register({
                required: "請再次輸入密碼",
                validate: (value) =>
                  value === watch("new_password") || "密碼兩次輸入不一樣",
              })}
              name="confirmPassword"
              label="確認新密碼"
              helperText={errors.confirmPassword?.message}
            />
          </Box>
          <Box p={1}>
            <Button fullWidth py="1rem" type="submit">
              <Typography color="textSecondary">更改密碼</Typography>
            </Button>
          </Box>
          {submitError && (
            <Box p={1} pt={0} color="#B70000">
              <Typography variant="footnote">密碼不正確</Typography>
            </Box>
          )}
        </form>
      </DialogWithCloseButton>
      <ErrorAlert open={errorAlert} handleClose={closeAlert}>
        系統出錯，請稍後再試。
      </ErrorAlert>
    </Box>
  )
}

export default ChangePW
